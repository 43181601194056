










































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import Client from "@/models/Client";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ClientService from "@/services/ClientService";
import LangModule from "@/store/LangModule";

@Component
export default class ClientTab extends Vue {
	@Prop() readonly client!: Client
	@Ref() readonly form!: HTMLFormElement
	@Prop() refresh!: any
	lang: any = getModule(LangModule).lang

	updateClient() {
		getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.operationConfirmation,
				() => ClientService.patchClient(this.client.id!, this.client)
		))
	}
}
